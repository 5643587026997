import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from "../../../components/BlogPost";
import Browser from "../../../components/BrowserDemo";
import { TwitterTweetEmbed } from "react-twitter-embed";
export const meta = {
  published: true,
  publishedAt: "2020-02-03",
  title: "I am joining Gatsby",
  summary: "I am joining Gatsby to work on open source R&D projects including Blocks, the visual editor for React apps, theme-ui, mdx and Gatsby themes! 🎉",
  image: "/static/images/gatsby.png",
  likes: 2015
};
export const _frontmatter = {};
const layoutProps = {
  meta,
  _frontmatter
};

const MDXLayout = ({
  children
}) => <BlogPost meta={meta}>{children}</BlogPost>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The cat is out of the bag: I am joining `}<a parentName="p" {...{
        "href": "https://gatsbyjs.com"
      }}>{`Gatsby`}</a>{` to work on open source R&D projects, including `}<a parentName="p" {...{
        "href": "https://blocks-ui.com"
      }}>{`Blocks`}</a>{`, `}<a parentName="p" {...{
        "href": "https://theme-ui.com"
      }}>{`theme-ui`}</a>{`, `}<a parentName="p" {...{
        "href": "https://mdxjs.com"
      }}>{`mdx`}</a>{` and Gatsby themes!`}</p>
    <p>{`I think Blocks is one of the most exciting open source projects in the React ecosystem right now. Blocks is a visual editor for your React apps: it renders a page of your app and lets you drag-and-drop components to edit the actual underlying code. 🤯`}</p>
    <TwitterTweetEmbed tweetId="1199384410199449600" mdxType="TwitterTweetEmbed" />
    <p>{`This has wide reaching implications for the design and development process. Rather than drawing a bunch of rectangles, designers can visually edit code to design new pages. Instead of handing off static images, they can submit pull requests with their new designs.`}</p>
    <p>{`I believe that a couple of years from now, visual editors will be standard in web development. Everybody will be using them. However, there is a lot of work left to make them great and I cannot wait to be a part of that!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      